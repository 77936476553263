<template>
  <tr class="row-data">
    <td
      @click="$emit('clickOnDateCell', date)"
      class="day-data sticky-columns-shadow"
      :class="[color_class]"
      style="width: 200px"
    >
      <div class="cell justify-start">
        <div class="d-flex justify-start pl-5">
          <span>{{ label }}</span>
        </div>
      </div>
    </td>
    <td
      v-for="column in nonStickyColumnHeaders"
      :key="`${date}-${column.group}-${column.column}`"
      :class="[
        {
          'sticky-columns-shadow': column.column === lastStickyColumn.column
        },
        getClass(
          configYearSalesTable[column.group].columns[column.column].kpi,
          getValue(column)
        ),
        { 'br-bolder': column.column === lastColumnOfEachGroup[column.group] },
        { summary: isSummary }
      ]"
      @mouseover="$emit('hoveredKpi', column)"
      @mouseleave="$emit('hoveredKpi', '')"
    >
      <div style="position:relative; height: 100%">
        <div class="cell">
          <a-kpi
            :kpi="configYearSalesTable[column.group].columns[column.column].kpi"
            :value="getValue(column)"
            x_small
            vertical
            v-if="
              column.group === 'otb' ||
                !getValue(column) ||
                kpiMode === 'hide_vs'
            "
            class="pt-4"
          />
          <template v-else-if="column.group === 'forecast'">
            <a-kpi
              :kpi="
                configYearSalesTable[column.group].columns[column.column].kpi
              "
              :value="getValue(column)"
              :vs_values="[getValue(column, 'bud')]"
              :vs_periods="['bud']"
              x_small
              vertical
            />
          </template>
          <template v-else>
            <a-kpi
              :kpi="
                configYearSalesTable[column.group].columns[column.column].kpi
              "
              :value="getValue(column)"
              x_small
              vertical
            />
            <div class="increment-wrapper">
              <a-increment
                :kpi="
                  configYearSalesTable[column.group].columns[column.column].kpi
                "
                :value="getValue(column, 'otb')"
                :vs_value="getValue(column)"
                :period="column.group"
                class="pt-2"
                :hide_tooltip="true"
                :absolute="kpiMode === 'absolute'"
              ></a-increment>
            </div>
          </template>
        </div>
        <div
          v-if="
            showProgressPercent(
              configYearSalesTable[column.group].columns[column.column].kpi
            )
          "
          class="percent-progress"
          :style="getPercentStyle(getValue(column))"
        ></div>
      </div>
    </td>
  </tr>
</template>
<script>
import aKpi from "@/components/rms_ui/aKpi.vue";
import aIncrement from "@/components/rms_ui/aIncrement.vue";

import { mapGetters, mapState } from "vuex";

export default {
  name: "a-rms-strategy-day-table-row",
  emits: ["clickOnDateCell", "hoveredKpi"],
  components: { aKpi, aIncrement },
  props: {
    row: Object,
    date: String,
    label: String,
    nonStickyColumnHeaders: Array,
    lastStickyColumn: Object,
    configYearSalesTable: Object,
    lastColumnOfEachGroup: Object,
    isSummary: Boolean,
    kpiMode: String
  },
  methods: {
    showProgressPercent(kpi) {
      let type = this.get_kpi(kpi).data_format;
      if (type === "percent1" || type === "percent2") {
        return true;
      } else {
        return false;
      }
    },
    getPercentStyle(value) {
      return "width:" + value + "%;";
    },
    getClass(kpi, value) {
      // If type of kpi is percent we must add a class to the cell named  kpi-percent-range-0-20, kpi-percent-range-20-40, kpi-percent-range-40-60, kpi-percent-range-60-80, kpi-percent-range-80-100
      let type = this.get_kpi(kpi).data_format;
      if (value === null) return "";
      if (type === "percent1" || type === "percent2") {
        value = 100 - value;
        if (value < 20) {
          return "kpi-percent-range-0-20";
        } else if (value >= 20 && value < 40) {
          return "kpi-percent-range-20-40";
        } else if (value >= 40 && value < 60) {
          return "kpi-percent-range-40-60";
        } else if (value >= 60 && value < 80) {
          return "kpi-percent-range-60-80";
        } else if (value >= 80) {
          return "kpi-percent-range-80-100";
        }
      } else {
        return "";
      }
    }
  },
  computed: {
    getStaticKpiValue() {
      return function(column, vs = undefined) {
        const spotIndex = vs
          ? vs
          : this.configYearSalesTable[column.group].columns[column.column]
              .block;
        return this.row &&
          this.row[spotIndex] &&
          this.row[spotIndex][
            this.configYearSalesTable[column.group].columns[column.column].kpi
          ]
          ? this.row[spotIndex][
              this.configYearSalesTable[column.group].columns[column.column].kpi
            ]
          : null;
      };
    },
    getDynamicKpiValue() {
      return function(column, dynamicKey = "roomtype", vs = undefined) {
        const spotIndex = vs
          ? vs
          : this.configYearSalesTable[column.group].columns[column.column]
              .block;

        return this.row &&
          this.row[spotIndex] &&
          this.row[spotIndex][dynamicKey] &&
          this.row[spotIndex][dynamicKey][column.column] &&
          this.row[spotIndex][dynamicKey][column.column][
            this.configYearSalesTable[column.group].columns[column.column].kpi
          ]
          ? this.row[spotIndex][dynamicKey][column.column][
              this.configYearSalesTable[column.group].columns[column.column].kpi
            ]
          : null;
      };
    },
    getForecastKpiValue() {
      return function(column) {
        if (column === "adr") {
          const forecast_rn = this.getForecastKpiValue("rn_adr");
          const forecast_room_revenue = this.getForecastKpiValue(
            "room_revenue"
          );
          return forecast_rn && forecast_room_revenue
            ? forecast_room_revenue / forecast_rn
            : null;
        }
        if (column === "occupancy") {
          const forecast_occupied = this.getForecastKpiValue("occupied");
          const forecast_available = this.getForecastKpiValue("available");
          return forecast_occupied && forecast_available
            ? (forecast_occupied / forecast_available) * 100
            : null;
        }
        const otb =
          this.row && this.row["otb"] ? this.row["otb"][column] : null;
        const stly =
          this.row && this.row["stly"] ? this.row["stly"][column] : null;
        const ly = this.row && this.row["ly"] ? this.row["ly"][column] : null;
        return otb && stly && ly ? otb - stly + ly : null;
      };
    },
    getValue() {
      return function(column, vs = undefined) {
        if (column.group === "roomtype") {
          return this.getDynamicKpiValue(column, "roomtype", vs);
        } else if (column.group === "segment") {
          return this.getDynamicKpiValue(column, "segment", vs);
        } else if (column.group === "forecast" && !vs) {
          return this.getForecastKpiValue(column.column);
        } else {
          return this.getStaticKpiValue(column, vs);
        }
      };
    },
    color_class() {
      if (!this.row || !this.row.otb || !this.row.bud) return "neutral";

      let diff = this.row.otb.occupancy - this.row.bud.occupancy;
      if (diff > 0) return "positive";
      if (diff < 0) return "negative";
      return "neutral";
    },
    ...mapState({
      rms: state => state.rms
    }),
    ...mapGetters({
      current_hotel: "current_hotel",
      get_kpi: "get_kpi"
    })
  }
};
</script>
<style lang="scss" scoped>
.sticky-columns-shadow {
  box-shadow: 1px 0px 0px 0px rgba(0, 0, 0, 0.15),
    2px 0px 0px 0px rgba(0, 0, 0, 0.12), 4px 0px 0px 0px rgba(0, 0, 0, 0.08);
}

.cell {
  // border-top: $borderType $borderColorV;

  line-height: 16px;
  font-size: 12px;
  font-weight: 600;
  text-align: center;
  white-space: nowrap;
  min-width: 100%;
  text-wrap: wrap;
  text-transform: uppercase;
  // display: flex;
  margin: 4px 0;
  justify-content: center;
  align-items: center;

  &.kpi-row {
    border-right: $borderType $borderColorV;
    border-bottom: 3px solid transparent;
  }

  &.kpi-hovered {
    border-bottom: 3px solid #2d6ece;
  }
}

table {
  border-spacing: 0;
}

th {
  // border-left: none;
  border: none;
  border-right: $borderType $borderColorV;
  border-bottom: $borderType $borderColorV;
  // padding: 5px;
  width: 100px;
  min-width: 100px;
  position: sticky;
  top: 0;
  background: white;
  color: #a0a0a0;
  font-weight: normal;
  height: $minHeightColRow;

  &:nth-child(1) {
    position: sticky;
    left: 0;
    background: white;
    color: #e0e0e0;
    font-weight: normal;
    z-index: 2;
    // border-bottom: $borderType $borderColorV;
    // border-right: $borderType $borderColorV;
  }
}

.header-row {
  th {
    border-right: none;
    border-bottom: none;
    top: $minHeightColRow;
    box-shadow: 1px 0px 0px 0px rgba(0, 0, 0, 0.14),
      0px 1px 0px 0px rgba(0, 0, 0, 0.15), 2px 0px 0px 0px rgba(0, 0, 0, 0.11),
      0px 2px 0px 0px rgba(0, 0, 0, 0.12), 4px 0px 0px 0px rgba(0, 0, 0, 0.07),
      0px 4px 0px 0px rgba(0, 0, 0, 0.08);
    // &:nth-child(2) {
    //   position: sticky;
    //   left: 100px;
    //   box-shadow: 1px 0px 0px 0px rgba(0, 0, 0, 0.14),
    //     0px 1px 0px 0px rgba(0, 0, 0, 0.15),
    //     2px 0px 0px 0px rgba(0, 0, 0, 0.11),
    //     0px 2px 0px 0px rgba(0, 0, 0, 0.12),
    //     4px 0px 0px 0px rgba(0, 0, 0, 0.07),
    //     0px 4px 0px 0px rgba(0, 0, 0, 0.08);
    // }
    &:nth-child(1) {
      z-index: 2;
      border-bottom: $borderType $borderColorV;
    }
  }
}

td {
  border: none;
  // border-left: none;
  // border-right: $borderType $borderColorV;
  // border-bottom: $borderType $borderColorV;
  // padding: 0 5px;
  width: 100px;
  min-width: 100px;
  background-color: white;
  height: $minHeightColRow + 4px;

  border-left: $borderType $borderColorV;
  border-right: 3px solid transparent;
  border-bottom: $borderType $borderColorV;
  padding: 5px 5px 2px 5px;

  &:nth-child(1) {
    position: sticky;
    left: 0;
    background: white;
    color: #e0e0e0;
    font-weight: normal;
    z-index: 1;
    padding: 5px;
  }

  // &:nth-child(2) {
  //   position: sticky;
  //   left: 100px;
  //   width: 100px;
  //   min-width: 100px;
  // }

  &:hover {
    background-color: #f5fdff !important;
  }

  .percent-progress {
    content: "";
    position: absolute;
    bottom: 0px;
    // left: 3px;
    width: calc(100% - 6px);
    height: 4px;
    border-radius: 5px;
    margin-top: 5px;
  }

  &.kpi-percent-range-0-20 {
    background-color: rgba(181, 68, 3, 0.2) !important;

    &.summary {
      background-color: #f0d9cc !important;
    }

    .percent-progress {
      background-color: rgba(181, 68, 3, 1) !important;
    }
  }

  &.kpi-percent-range-20-40 {
    background-color: rgba(219, 131, 0, 0.2) !important;

    &.summary {
      background-color: #f7e6cc !important;
    }

    .percent-progress {
      background-color: rgba(219, 131, 0, 1) !important;
    }
  }

  &.kpi-percent-range-40-60 {
    background-color: rgba(246, 185, 0, 0.2) !important;

    &.summary {
      background-color: #fdf1cc !important;
    }

    .percent-progress {
      background-color: rgba(246, 185, 0, 1) !important;
    }
  }

  &.kpi-percent-range-60-80 {
    background-color: rgba(158, 143, 2, 0.2) !important;

    &.summary {
      background-color: #ebe8cc !important;
    }

    .percent-progress {
      background-color: rgba(158, 143, 2, 1) !important;
    }
  }

  &.kpi-percent-range-80-100 {
    background-color: rgba(79, 131, 2, 0.2) !important;

    &.summary {
      background-color: #dbe6cc !important;
    }

    .percent-progress {
      background-color: rgba(79, 131, 2, 1) !important;
    }
  }
}

tr.row-data {
  td.day-data {
    font-size: 0.8rem;
    font-weight: 700;
    color: #555;
    width: 200px;
    text-transform: uppercase;
    cursor: pointer;

    &::after {
      content: " ";
      position: absolute;
      top: 0;
      left: 3px;
      height: calc(100% - 8px);
      width: 4px;
      background-color: grey;
      z-index: 10;
      border-radius: 5px;
      margin: 4px 0;
    }

    &.positive::after {
      background-color: rgb(23, 171, 112);
    }

    &.negative::after {
      background-color: rgb(172, 0, 0);
    }

    &.neutral::after {
      background-color: orange;
    }
  }

  &:hover {
    td.sticky-columns-shadow {
      border-right: 3px solid #2d6ece;
    }
  }

  td.previous {
    opacity: 0.7;

    &:hover {
      opacity: 1;
    }
  }

  &.quarter {
    td {
      background-color: #f0f0f0;
    }
  }

  &.summary {
    td {
      background-color: #ebebeb;
    }
  }
}

tr.summary {
  box-shadow: 0px -1px 0px 0px rgba(0, 0, 0, 0.15),
    0px -2px 0px 0px rgba(0, 0, 0, 0.12), 0px -4px 0px 0px rgba(0, 0, 0, 0.08);
}

.increment-wrapper ::v-deep {
  .kpi-wrapper-2 {
    font-size: 19px;
    line-height: 21px;
    padding-top: 6px;
  }
}

.br-bolder {
  border-right: 2px solid #ccc !important;
}
</style>
