<template>
  <tr class="row-data">
    <td
      @click="$emit('clickOnDateCell', date)"
      class="day-data sticky-columns-shadow"
      :class="[color_class, { weekend: isWeekend }]"
    >
      <div class="cell" v-if="!isSummary">
        <div style="width: 100%; gap: 3px" class="d-flex flex-column">
          <div class="d-flex justify-start pl-5">
            <span v-html="day_of_week"></span>
            <v-chip v-if="is_today" dark x-small color="blue" class="ml-2">
              {{ $t("today") }}
            </v-chip>
            <v-spacer></v-spacer>
            <a-rms-strategy-day-comments
              v-if="comments.length > 0"
              :date="date"
              :comments="comments"
            />
            <a-rms-strategy-day-events
              v-for="impact in impacts"
              :key="impact.impact"
              :impact="impact"
              :events="events"
              :date="date"
            />
          </div>
          <div
            v-if="kpiMode !== 'hide_vs'"
            class="d-flex justify-start pl-5 grey--text"
          >
            <span v-html="day_of_week_vs"></span>
            <v-spacer></v-spacer>
            <a-rms-strategy-day-events
              v-for="impact_vs in impacts_vs"
              :key="impact_vs.impact"
              :impact="impact_vs"
              :events="events_vs"
              :date="date_vs"
            />
          </div>
        </div>
      </div>
      <div class="cell justify-start" v-else>
        <div class="d-flex justify-start pl-5">
          <span>{{ $t("result_period") }}</span>
        </div>
      </div>
    </td>
    <td
      v-for="column in nonStickyColumnHeaders"
      :key="`${date}-${column.group}-${column.column}`"
      :class="[
        {
          'sticky-columns-shadow': column.column === lastStickyColumn.column
        },
        { weekend: isWeekend },
        getClass(
          configStrategyTable[column.group].columns[column.column].kpi,
          getValue(column),
          column
        ),
        { 'br-bolder': column.column === lastColumnOfEachGroup[column.group] },
        { previous: isPrevious },
        { summary: isSummary }
      ]"
      @mouseover="$emit('hoveredKpi', column)"
      @mouseleave="$emit('hoveredKpi', '')"
    >
      <div
        class="cell"
        :class="
          showProgressPercent(
            configStrategyTable[column.group].columns[column.column].kpi
          )
            ? 'percent-bar'
            : ''
        "
      >
        <template v-if="column.group.startsWith('pickup_')">
          <a-kpi
            v-if="rms.strategy_vs_spot === 'stly'"
            :kpi="configStrategyTable[column.group].columns[column.column].kpi"
            :value="getStaticKpiValue(column)"
            :vs_values="
              kpiMode !== 'hide_vs'
                ? [
                    getStaticKpiValue(
                      column,
                      `pickup_stly_${column.group.slice(-1)}`
                    )
                  ]
                : []
            "
            :vs_periods="[rms.strategy_vs_spot]"
            :absolute="kpiMode === 'absolute'"
            x_small
            vertical
          />
          <a-kpi
            v-else
            :kpi="configStrategyTable[column.group].columns[column.column].kpi"
            :value="getStaticKpiValue(column)"
            :absolute="kpiMode === 'absolute'"
            x_small
            vertical
          />
        </template>
        <template v-else-if="column.group === 'rates'">
          <div class="d-flex justify-center" @click="goToRate(column)">
            <!-- <div class="d-flex justify-center"> -->
            <a-kpi
              :kpi="
                configStrategyTable[column.group].columns[column.column].kpi
              "
              :value="getValue(column)"
              :absolute="kpiMode === 'absolute'"
              x_small
              vertical
            />
            <a-kpi
              v-if="has_pvp_change_today && column.column === 'current_rate'"
              :kpi="
                configStrategyTable[column.group].columns[column.column].kpi
              "
              :value="getValue(column)"
              :vs_values="
                kpiMode !== 'hide_vs'
                  ? [getValue(column) - pvpMlosChanges('pvp', 'pickup0')]
                  : []
              "
              :vs_periods="['previous_price']"
              :absolute="kpiMode === 'absolute'"
              class="mt-1"
              x_small
              hide_kpi
              vertical
            />
          </div>
        </template>
        <template v-else-if="column.column === 'otb'">
          <a-kpi
            :kpi="configStrategyTable[column.group].columns[column.column].kpi"
            :value="getValue(column)"
            :absolute="kpiMode === 'absolute'"
            x_small
            vertical
          />
        </template>
        <template
          v-else-if="
            ['stly', 'stlyn', 'ly', 'lyn', 'bud', 'spot_date'].includes(
              column.column
            )
          "
        >
          <a-kpi
            :kpi="configStrategyTable[column.group].columns[column.column].kpi"
            :value="getValue(column)"
            :absolute="kpiMode === 'absolute'"
            :class="kpiMode !== 'hide_vs' ? 'mb-1' : ''"
            x_small
          />
          <a-kpi
            :kpi="configStrategyTable[column.group].columns[column.column].kpi"
            :value="getValue(column, 'otb')"
            :vs_values="kpiMode !== 'hide_vs' ? [getValue(column)] : []"
            :vs_periods="[column.column]"
            :absolute="kpiMode === 'absolute'"
            class="a-kpi-tooltip-hide"
            x_small
            hide_kpi
            hide_tooltip
          />
        </template>
        <template
          v-else-if="column.group === 'roomtype' || column.group === 'segment'"
        >
          <!-- {{rms.strategy_vs_spot}} => STLY -->
          <!-- {{column.vs}}            => SPOT_DATE -->
          <a-kpi
            :kpi="configStrategyTable[column.group].columns[column.column].kpi"
            :value="getValue(column)"
            :vs_values="
              kpiMode !== 'hide_vs' ? [getValue(column, column.vs)] : []
            "
            :vs_periods="[column.vs]"
            :absolute="kpiMode === 'absolute'"
            x_small
            vertical
          />
        </template>
        <template v-else>
          <a-kpi
            :kpi="configStrategyTable[column.group].columns[column.column].kpi"
            :value="getValue(column)"
            :vs_values="
              kpiMode !== 'hide_vs'
                ? [getValue(column, rms.strategy_vs_spot)]
                : []
            "
            :vs_periods="[rms.strategy_vs_spot]"
            :absolute="kpiMode === 'absolute'"
            x_small
            vertical
          />
        </template>
        <div
          v-if="
            showProgressPercent(
              configStrategyTable[column.group].columns[column.column].kpi
            )
          "
          class="percent-progress"
          :style="getPercentStyle(getValue(column))"
        ></div>
      </div>
    </td>
  </tr>
</template>

<script>
import { getFriendlyDayStrategy } from "@/utils.js";
import { mapGetters, mapState } from "vuex";
import Vue from "vue";

import aRmsStrategyDayEvents from "@/components/rms/aRmsStrategyDayEvents";
import aRmsStrategyDayComments from "@/components/rms/aRmsStrategyDayComments";
import aKpi from "@/components/rms_ui/aKpi.vue";

export default {
  name: "a-rms-strategy-day-table-row",
  emits: ["clickOnDateCell", "hoveredKpi"],
  components: {
    aKpi,
    aRmsStrategyDayEvents,
    aRmsStrategyDayComments
  },
  props: {
    row: Object,
    date: String,
    nonStickyColumnHeaders: Array,
    lastStickyColumn: Object,
    configStrategyTable: Object,
    lastColumnOfEachGroup: Object,
    isSummary: Boolean,
    kpiMode: String
  },
  data() {
    return {
      kpis_data: {}
    };
  },
  methods: {
    showProgressPercent(kpi) {
      let type = this.kpis_data[kpi]
        ? this.kpis_data[kpi].data_format
        : "default";
      return type === "percent1" || type === "percent2";
    },
    getPercentStyle(value) {
      return "width:" + Math.min(100, value) + "%;";
    },
    getClass(kpi, value, column) {
      // If type of kpi is percent we must add a class to the cell named  kpi-percent-range-0-20, kpi-percent-range-20-40, kpi-percent-range-40-60, kpi-percent-range-60-80, kpi-percent-range-80-100
      let type = this.kpis_data[kpi]
        ? this.kpis_data[kpi].data_format
        : "default";
      if (value === null) return "";
      if (type === "percent1" || type === "percent2") {
        if (value < 20) {
          return "kpi-percent-range-0-20";
        } else if (value >= 20 && value < 40) {
          return "kpi-percent-range-20-40";
        } else if (value >= 40 && value < 60) {
          return "kpi-percent-range-40-60";
        } else if (value >= 60 && value < 80) {
          return "kpi-percent-range-60-80";
        } else if (value >= 80) {
          return "kpi-percent-range-80-100";
        }
      } else if (column.group === "roomtype") {
        return !this.isSummary &&
          value > this.configStrategyTable.roomtype.columns[column.column].limit
          ? "limit"
          : "";
      } else if (column.group.startsWith("pickup_")) {
        return value <=
          this.configStrategyTable[column.group].columns[column.column].limit
          ? "limit"
          : "";
      } else if (
        column.group === "inventory" &&
        (column.column === "available" || column.column === "available_pace")
      ) {
        return !this.isSummary &&
          value <=
            this.configStrategyTable[column.group].columns[column.column].limit
          ? "limit"
          : "";
      } else if (
        column.group === "rates" &&
        (column.column === "compset_min" || column.column === "compset_avg")
      ) {
        return !this.isSummary && !this.isPrevious ? "kpi-pointer" : "";
      } else {
        return "";
      }
    },
    pvpMlosChanges(kpi, spot) {
      return this.rms.pvp_mlos_changes_daily &&
        this.rms.pvp_mlos_changes_daily[this.date] &&
        this.rms.pvp_mlos_changes_daily[this.date][kpi]
        ? this.rms.pvp_mlos_changes_daily[this.date][kpi][spot]
        : null;
    },
    goToRate(column) {
      if (
        !this.isSummary &&
        !this.isPrevious &&
        (column.column === "compset_min" || column.column === "compset_avg")
      ) {
        this.$router.push({
          name: "rate",
          params: {
            hotel_slug: this.current_hotel.settings.slug
          },
          query: {
            date: this.date
          }
        });
      }
    },
    getUniqueKpis(configTable) {
      const kpisSet = new Set();

      // Iterate through each category in the config table
      Object.values(configTable).forEach(category => {
        // Check if the category has columns
        if (category.columns) {
          // Iterate through each column in the category
          Object.values(category.columns).forEach(column => {
            // If the column has a kpi property, add it to the set
            if (column.kpi) {
              kpisSet.add(column.kpi);
            }
          });
        }
      });

      // Convert the set to an array and return
      return Array.from(kpisSet);
    }
  },
  computed: {
    isWeekend() {
      return !this.isSummary && Vue.moment(this.date).isoWeekday() > 4;
    },
    isPrevious() {
      return (
        !this.isSummary &&
        Vue.moment(this.date).isBefore(
          Vue.moment()
            .endOf("day")
            .add(-1, "days")
        )
      );
    },
    getStaticKpiValue() {
      return function(column, vs = undefined) {
        const spotIndex = vs
          ? vs
          : this.configStrategyTable[column.group] &&
            this.configStrategyTable[column.group].columns[column.column].block;
        return this.row &&
          this.row[spotIndex] &&
          this.configStrategyTable[column.group] &&
          this.row[spotIndex][
            this.configStrategyTable[column.group].columns[column.column].kpi
          ]
          ? this.row[spotIndex][
              this.configStrategyTable[column.group].columns[column.column].kpi
            ]
          : null;
      };
    },
    getDynamicKpiValue() {
      return function(column, dynamicKey = "roomtype", vs = undefined) {
        const spotIndex = vs
          ? vs
          : this.configStrategyTable[column.group].columns[column.column].block;

        return this.row &&
          this.row[spotIndex] &&
          this.row[spotIndex][dynamicKey] &&
          this.row[spotIndex][dynamicKey][column.column] &&
          this.row[spotIndex][dynamicKey][column.column][
            this.configStrategyTable[column.group].columns[column.column].kpi
          ]
          ? this.row[spotIndex][dynamicKey][column.column][
              this.configStrategyTable[column.group].columns[column.column].kpi
            ]
          : null;
      };
    },
    getValue() {
      return function(column, vs = undefined) {
        if (column.group === "roomtype") {
          return this.getDynamicKpiValue(column, "roomtype", vs);
        } else if (column.group === "segment") {
          return this.getDynamicKpiValue(column, "segment", vs);
        } else if (column.group === "cityMKD") {
          return column.column === "MKD-Final" || vs
            ? this.getStaticKpiValue(column, vs)
            : this.getStaticKpiValue(
                { column: "MKD-Final", group: "cityMKD" },
                vs
              ) - this.getStaticKpiValue(column, vs);
        } else if (column.column === "room_revenue_other") {
          return (
            this.getStaticKpiValue(
              { column: "room_revenue_total", group: "revenue" },
              vs
            ) -
            this.getStaticKpiValue(
              { column: "room_revenue", group: "revenue" },
              vs
            )
          );
        } else {
          return this.getStaticKpiValue(column, vs);
        }
      };
    },
    color_class() {
      if (!this.row || !this.row.otb || !this.row.bud) return "neutral";

      let diff = this.row.otb.occupancy - this.row.bud.occupancy;
      if (diff > 0) return "positive";
      if (diff < 0) return "negative";
      return "neutral";
    },
    day_of_week() {
      return getFriendlyDayStrategy(this.date, true);
    },
    day_of_week_vs() {
      return getFriendlyDayStrategy(this.date_vs, true);
    },
    is_today() {
      return this.date === Vue.moment().format("YYYY-MM-DD");
    },
    events() {
      let events = this.date
        ? this.calendar_store.hotel_events.filter(e =>
            Vue.moment(this.date).isBetween(
              e.start_date,
              e.end_date,
              undefined,
              "[]"
            )
          )
        : [];
      return events;
    },
    events_vs() {
      let events = this.date_vs
        ? this.calendar_store.hotel_events.filter(e =>
            Vue.moment(this.date_vs).isBetween(
              e.start_date,
              e.end_date,
              undefined,
              "[]"
            )
          )
        : [];
      return events;
    },
    impacts() {
      let events_count = this.calendar_store.impacts.map(i => {
        return {
          impact: i.value,
          color: i.color,
          count: this.events.filter(e => e.impact === i.value).length
        };
      });
      return events_count.filter(e => e.count > 0);
    },
    impacts_vs() {
      let events_count = this.calendar_store.impacts.map(i => {
        return {
          impact: i.value,
          count: this.events_vs.filter(e => e.impact === i.value).length
        };
      });
      return events_count.filter(e => e.count > 0);
    },
    date_vs() {
      let date_vs = {};
      let items = this.calendar_store.hotel_comparative_dates;
      if (Object.keys(items).length > 0 && items.hasOwnProperty(this.date)) {
        date_vs = items[this.date].date_vs;
      }
      return date_vs;
    },
    has_pvp_change_today() {
      return (
        this.pvpMlosChanges("pvp", "pickup0") &&
        this.pvpMlosChanges("pvp", "pickup0") !== 0
      );
    },
    pvp_class() {
      if (this.has_pvp_change_today) {
        return this.pvpMlosChanges("pvp", "pickup0") < 0
          ? "red--text"
          : "blue--text";
      }
      return "";
    },
    comments() {
      return this.calendar_store.hotel_comments.filter(
        c => c.date === this.date
      );
    },
    ...mapState({
      rms: state => state.rms,
      calendar_store: state => state.calendar
    }),
    ...mapGetters({
      current_hotel: "current_hotel",
      get_kpi: "get_kpi"
    })
  },
  mounted() {
    // Get unique kpis from columns
    this.kpis = this.getUniqueKpis(this.configStrategyTable);
    const kpis_data = {};
    this.kpis.forEach(kpi => {
      kpis_data[kpi] = this.get_kpi(kpi);
    });
    this.kpis_data = Object.freeze(kpis_data);
  }
};
</script>

<style lang="scss" scoped>
.sticky-columns-shadow {
  box-shadow: 1px 0px 0px 0px rgba(0, 0, 0, 0.15),
    2px 0px 0px 0px rgba(0, 0, 0, 0.12), 4px 0px 0px 0px rgba(0, 0, 0, 0.08);
}
table {
  border-spacing: 0;
}
tr.summary {
  box-shadow: 0px -1px 0px 0px rgba(0, 0, 0, 0.15),
    0px -2px 0px 0px rgba(0, 0, 0, 0.12), 0px -4px 0px 0px rgba(0, 0, 0, 0.08);
}
.cell {
  line-height: 16px;
  font-size: 12px;
  font-weight: 600;
  min-width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  text-align: center;
  text-wrap: wrap;
  text-transform: uppercase;
  white-space: nowrap;
  &.kpi-row {
    border-right: $borderType $borderColorV;
    border-bottom: 3px solid transparent;
  }
  &.kpi-hovered {
    border-bottom: 3px solid #2d6ece;
  }
  &.percent-bar {
    justify-content: space-between;
    height: 100%;
    ::v-deep .a-kpi {
      margin: 0 !important;
    }
  }
}
th {
  background: white;
  border: none;
  border-right: $borderType $borderColorV;
  border-bottom: $borderType $borderColorV;
  color: #a0a0a0;
  font-weight: normal;
  height: $minHeightColRow;
  position: sticky;
  top: 0;
  &:nth-child(1) {
    position: sticky;
    left: 0;
    background: white;
    color: #e0e0e0;
    font-weight: normal;
    z-index: 2;
  }
}
.header-row {
  th {
    border-right: none;
    border-bottom: none;
    top: $minHeightColRow;
    box-shadow: 1px 0px 0px 0px rgba(0, 0, 0, 0.14),
      0px 1px 0px 0px rgba(0, 0, 0, 0.15), 2px 0px 0px 0px rgba(0, 0, 0, 0.11),
      0px 2px 0px 0px rgba(0, 0, 0, 0.12), 4px 0px 0px 0px rgba(0, 0, 0, 0.07),
      0px 4px 0px 0px rgba(0, 0, 0, 0.08);
    &:nth-child(1) {
      z-index: 2;
      border-bottom: $borderType $borderColorV;
    }
  }
}
tbody {
  tr {
    height: 1px;
    td {
      height: inherit;
      .cell {
        /* © */
      }
    }
  }
}
td {
  border: none;
  background-color: white;
  border-left: $borderType $borderColorV;
  border-right: 3px solid transparent;
  border-bottom: $borderType $borderColorV;
  padding: 5px;
  &:nth-child(1) {
    position: sticky;
    left: 0;
    background: white;
    color: #e0e0e0;
    font-weight: normal;
    z-index: 1;
    padding: 5px;
  }
  &:hover {
    background-color: #f5fdff !important;
  }
  .percent-progress {
    border-radius: 5px;
    content: "";
    height: 4px;
    margin-top: 3px;
    width: calc(100% - 6px);
  }
  &.kpi-percent-range-0-20 {
    background-color: rgba(181, 68, 3, 0.2) !important;
    &.summary {
      background-color: #f0d9cc !important;
    }
    .percent-progress {
      background-color: rgba(181, 68, 3, 1) !important;
    }
  }
  &.kpi-percent-range-20-40 {
    background-color: rgba(219, 131, 0, 0.2) !important;
    &.summary {
      background-color: #f7e6cc !important;
    }
    .percent-progress {
      background-color: rgba(219, 131, 0, 1) !important;
    }
  }
  &.kpi-percent-range-40-60 {
    background-color: rgba(246, 185, 0, 0.2) !important;
    &.summary {
      background-color: #fdf1cc !important;
    }
    .percent-progress {
      background-color: rgba(246, 185, 0, 1) !important;
    }
  }
  &.kpi-percent-range-60-80 {
    background-color: rgba(158, 143, 2, 0.2) !important;
    &.summary {
      background-color: #ebe8cc !important;
    }
    .percent-progress {
      background-color: rgba(158, 143, 2, 1) !important;
    }
  }
  &.kpi-percent-range-80-100 {
    background-color: rgba(79, 131, 2, 0.2) !important;
    &.summary {
      background-color: #dbe6cc !important;
    }
    .percent-progress {
      background-color: rgba(79, 131, 2, 1) !important;
    }
  }
}
tr.row-data {
  td.day-data {
    cursor: pointer;
    color: #555;
    font-size: 0.8rem;
    font-weight: 700;
    text-transform: uppercase;
    width: 200px;
    &::after {
      content: " ";
      position: absolute;
      top: 0;
      left: 3px;
      height: calc(100% - 8px);
      width: 4px;
      background-color: grey;
      z-index: 10;
      border-radius: 5px;
      margin: 4px 0;
    }
    &.positive::after {
      background-color: rgb(23, 171, 112);
    }
    &.negative::after {
      background-color: rgb(172, 0, 0);
    }
    &.neutral::after {
      background-color: orange;
    }
  }
  &:hover {
    td.sticky-columns-shadow {
      border-right: 3px solid #2d6ece;
    }
  }
  td.weekend {
    background-color: #fffef4;
  }
  td.previous {
    opacity: 1;
    background-color: #e5e5e5;
    &:hover {
      opacity: 1;
    }
  }
  &.summary {
    td {
      background-color: #ebebeb;
    }
  }
  td.limit {
    color: red;
  }
  .kpi-pointer {
    cursor: pointer;
  }
}
.br-bolder {
  border-right: 2px solid #ccc !important;
}
</style>
