var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"color":"grey lighten-4","outlined":""}},[_c('v-card-title',{staticClass:"text-uppercase font-weight-bold grey--text text--darken-3"},[_vm._v(" "+_vm._s(_vm.$t("rates"))+" ")]),_c('v-card-text',{staticClass:"white pa-3",staticStyle:{"height":"90%"}},[(_vm.day_rates.report)?_c('v-data-table',{key:("rate-table-" + _vm.rate_date_to_compare),attrs:{"headers":_vm.rateDayDetailHeaders,"items":_vm.comparisonItems,"item-key":"id","items-per-page":15,"hidden-default-footer":"","loading":!_vm.day_rates.report},scopedSlots:_vm._u([{key:"item.label",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"caption font-weight-medium d-flex",class:{ 'text-uppercase': item.self === 1 }},[_c('span',{class:item.self ? 'font-weight-bold' : ''},[_vm._v(_vm._s(item.label))])])]}},{key:"item.min_price",fn:function(ref){
var item = ref.item;
return [(item.price)?_c('a-kpi',{staticClass:"subtitle-2",class:item.self ? 'font-weight-bold' : '',attrs:{"kpi":'min_price',"value":item.price,"vs_values":[_vm.competitorComparePrice(item.competitor_id)],"vs_periods":['pickup_1'],"absolute":true},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('v-tooltip',{attrs:{"bottom":"","content-class":"a-rate-tooltip"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(item.is_mobile_offer)?_c('v-icon',_vm._g({staticClass:"mr-2",staticStyle:{"margin-bottom":"1px"},attrs:{"color":"yellow darken-2","x-small":""}},on),[_vm._v("mdi-cellphone")]):_vm._e()]}}],null,true)},[_c('span',{staticClass:"text-center black--text",staticStyle:{"font-size":"13px","line-height":"11px"},domProps:{"innerHTML":_vm._s(_vm.$t('is_mobile_offer'))}})]),_c('v-tooltip',{attrs:{"bottom":"","content-class":"a-rate-tooltip"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(item.is_genius_offer)?_c('span',_vm._g({staticClass:"blue--text text--darken-4 mt-n1 mr-2 ",staticStyle:{"font-size":"13px","font-weight":"600"}},on),[_vm._v(".g")]):_vm._e()]}}],null,true)},[_c('span',{staticClass:"text-center black--text",staticStyle:{"font-size":"13px","line-height":"11px"},domProps:{"innerHTML":_vm._s(_vm.$t('is_genius_offer'))}})])]},proxy:true}],null,true)}):_c('v-chip',{attrs:{"outlined":"","color":"red","small":"","dark":""}},[_vm._v(" SOLD OUT")])]}},{key:"item.regime",fn:function(ref){
var item = ref.item;
return [(item.regime === 'alo')?_c('v-icon',{attrs:{"color":"grey darken-2","small":""}},[_vm._v("mdi-bed-king-outline")]):_vm._e(),(item.regime === 'ad')?_c('v-icon',{attrs:{"color":"grey darken-2","small":""}},[_vm._v("mdi-coffee-outline")]):_vm._e(),(item.regime === 'mp')?_c('v-icon',{attrs:{"color":"grey darken-2","small":""}},[_vm._v("mdi-silverware-variant")]):_vm._e(),(item.regime === 'pc')?_c('v-icon',{attrs:{"color":"grey darken-2","small":""}},[_vm._v("mdi-silverware")]):_vm._e(),_c('span',{staticClass:"caption ml-1",class:item.self ? 'font-weight-bold' : ''},[_vm._v(_vm._s(_vm.$t(item.regime)))])]}},{key:"item.room_type_name",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"caption font-weight-medium",class:item.self ? 'font-weight-bold' : ''},[_vm._v(_vm._s(item.room_type_name))])]}},{key:"item.refund_policy",fn:function(ref){
var item = ref.item;
return [(item.free_cancelation)?_c('v-chip',{class:item.self ? 'font-weight-bold' : '',attrs:{"color":"green","outlined":"","small":""}},[_c('v-icon',{attrs:{"left":"","small":""}},[_vm._v("mdi-numeric-"+_vm._s(item.free_cancelation_days)+"-circle")]),_vm._v(" "+_vm._s(_vm.$t("free_cancelation"))+" ")],1):_vm._e(),(item.no_refundable)?_c('v-chip',{class:item.self ? 'font-weight-bold' : '',attrs:{"color":"red darken-3","outlined":"","small":""}},[_vm._v(" "+_vm._s(_vm.$t("no_refundable"))+" ")]):_vm._e(),(item.price && !item.no_refundable && !item.free_cancelation)?_c('v-chip',{class:item.self ? 'font-weight-bold' : '',attrs:{"color":"yellow darken-3","outlined":"","small":""}},[_vm._v(" "+_vm._s(_vm.$t("partial_refundable"))+" ")]):_vm._e()]}},{key:"item.minimum_nights",fn:function(ref){
var item = ref.item;
return [_c('span',{class:item.self ? 'font-weight-bold' : ''},[_vm._v(" "+_vm._s(item.minimum_nights)+" ")])]}},{key:"item.capacity",fn:function(ref){
var item = ref.item;
return [_vm._l((item.room_type_adults),function(i){return _c('v-icon',{key:'type_adults_' +
              i +
              '_' +
              item.competitor_id +
              '_' +
              _vm.rate_date_to_compare,staticClass:"ml-n1",attrs:{"color":"grey darken-2","small":""}},[_vm._v("mdi-account")])}),_c('span',{staticClass:"pa-1"}),_vm._l((item.room_type_childs),function(i){return _c('v-icon',{key:'type_childs_' +
              i +
              '_' +
              item.competitor_id +
              '_' +
              _vm.rate_date_to_compare,staticClass:"ml-n1 mt-1",attrs:{"color":"grey darken-2","x-small":""}},[_vm._v("mdi-account")])})]}}],null,false,3335995543)}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }