<template>
  <div id="market-demand" class="d-flex rate-data-row">
    <div class="item rate-labels semibold">
      <div
        v-if="$vuetify.breakpoint.smAndUp"
        class="simple_view text-uppercase"
      >
        <v-avatar color="primary" class="mt-n1" size="16">
          <span class="white--text" style="font-size:9px;font-weight: 400;"
            >CD</span
          >
        </v-avatar>
        {{ $t("cityMKD") }}
      </div>
      <div v-else class="simple_view py-0 pl-2">
        <v-tooltip right>
          <template v-slot:activator="{ on }">
            <v-btn
              v-on="on"
              fab
              x-small
              depressed
              color="primary"
              class="ma-0 v-list-item__avatar subtitle-2 white--text text-uppercase justify-center"
              >CD
            </v-btn>
          </template>
          <span>{{ $t("cityMKD") }}</span>
        </v-tooltip>
      </div>
    </div>
    <div class="item days">
      <div
        class="days-wrapper"
        :class="'x' + days_per_screen"
        v-if="days.length > 0"
      >
        <div
          v-for="(day, index) in days"
          :key="'market-demand-' + day"
          class="day"
          :class="getKpiPercentClassByValue(daily_demand[day])"
          @mouseover="
            date_hover = day;
            day_hover = index;
          "
          @mouseleave="
            date_hover = '';
            day_hover = '';
          "
        >
          <div
            v-if="mustRender(day)"
            class="day-date semibold"
            :class="'visible-day visible-day-' + index"
          >
            <div class="simple_view" v-if="daily_demand && daily_demand[day]">
              <div class="rate_data_item">
                <span class="block rate_data_main">
                  <a-kpi
                    v-if="daily_demand[day]"
                    :kpi="'market_demand'"
                    :value="daily_demand[day]"
                    :increment="false"
                    class="font-weight-bold"
                  ></a-kpi>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { mapState, mapGetters } from "vuex";
import aKpi from "@/components/aKpi";

export default {
  name: "market-demand",
  components: {
    aKpi
  },
  props: [
    "days",
    "days_per_screen",
    "competitor",
    "daily_demand",
    "days_to_render",
    "groupDisplay"
  ],
  data: vm => ({
    hover: false
  }),
  methods: {
    getKpiPercentClassByValue(current_value) {
      const value = 100 - current_value;
      if (value < 20) {
        return "kpi-percent-range-0-20";
      } else if (value >= 20 && value < 40) {
        return "kpi-percent-range-20-40";
      } else if (value >= 40 && value < 60) {
        return "kpi-percent-range-40-60";
      } else if (value >= 60 && value < 80) {
        return "kpi-percent-range-60-80";
      } else if (value >= 80) {
        return "kpi-percent-range-80-100";
      }
    },
    showDate(date, competitor_id) {
      if (this.groupDisplay) {
        const c = this.getCompetitorById(competitor_id);

        this.$store.dispatch("setCurrentHotelById", c.hotel_id);
        this.$router.push({ name: "rate" });
      } else {
        this.show_rate_date_to_compare = true;
        this.$store.dispatch("setComparedCompetitors", [competitor_id]);
        this.$store.dispatch("setRateDateToCompare", date);
      }
    },
    is_weekend(date) {
      var d = Vue.moment(date);
      var dow = d.day();
      return dow > 4 || dow === 0 ? true : false;
    },
    getDOW(date) {
      return Vue.moment(date).format("d");
    },
    getDayName(date) {
      return this.$vuetify.breakpoint.xlOnly
        ? Vue.moment(date).format("dddd")
        : Vue.moment(date).format("dd");
    },
    getDay(date) {
      return Vue.moment(date).format("D");
    },
    getDayShortName(date) {
      return Vue.moment(date).format("dd");
    },
    getMonth(date) {
      return Vue.moment(date).format("MM");
    },
    getMonthShortName(date) {
      return Vue.moment(date)
        .format("MMM")
        .replace(".", "");
    },
    getMonthName(date) {
      return Vue.moment(date).format("MMMM");
    },
    getShortYear(date) {
      return Vue.moment(date).format("YY");
    },
    isHover(date) {
      return this.date_hover === date;
    },
    mustRender(date) {
      // Must render if date inside days_per_screen
      return this.days_to_render.indexOf(date) >= 0;
    }
  },
  computed: {
    show_rate_date_to_compare: {
      get() {
        return this.$store.state.rate.show_rate_date_to_compare;
      },
      set(value) {
        this.$store.dispatch("showRateDateToCompare", value);
      }
    },
    date_hover: {
      get() {
        return this.$store.state.rate.date_hover;
      },
      set(value) {
        this.$store.dispatch("setRateDateHover", value);
      }
    },
    day_hover: {
      get() {
        return this.$store.state.rate.day_hover;
      },
      set(value) {
        this.$store.dispatch("setRateDayHover", value);
      }
    },
    ...mapState({
      invisible_competitors: state => state.rate.invisible_competitors,
      parity_colors: state => state.parity_colors
    }),
    ...mapGetters({
      current_hotel: "current_hotel",
      getCompetitorById: "getCompetitorById"
    })
  }
};
</script>
<style lang="scss" scoped>
.kpi-percent-range-0-20 {
  background-color: rgba(181, 68, 3, 0.2) !important;
  &.summary {
    background-color: #f0d9cc !important;
  }
  .percent-progress {
    background-color: rgba(181, 68, 3, 1) !important;
  }
}
.kpi-percent-range-20-40 {
  background-color: rgba(219, 131, 0, 0.2) !important;
  &.summary {
    background-color: #f7e6cc !important;
  }
  .percent-progress {
    background-color: rgba(219, 131, 0, 1) !important;
  }
}
.kpi-percent-range-40-60 {
  background-color: rgba(246, 185, 0, 0.2) !important;
  &.summary {
    background-color: #fdf1cc !important;
  }
  .percent-progress {
    background-color: rgba(246, 185, 0, 1) !important;
  }
}
.kpi-percent-range-60-80 {
  background-color: rgba(158, 143, 2, 0.2) !important;
  &.summary {
    background-color: #ebe8cc !important;
  }
  .percent-progress {
    background-color: rgba(158, 143, 2, 1) !important;
  }
}
.kpi-percent-range-80-100 {
  background-color: rgba(79, 131, 2, 0.2) !important;
  &.summary {
    background-color: #dbe6cc !important;
  }
  .percent-progress {
    background-color: rgba(79, 131, 2, 1) !important;
  }
}
.rate_data_main {
  line-height: 40px;
}
</style>
